* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Raleway", sans-serif;

}

html {
  scroll-behavior: smooth;
}

.hero {
  background-image: linear-gradient(
     rgba(5,7,12,0.40),
     rgba(5,7,12,0.40)
    ),
    url("../img/icg_ppal.jpg");
  width: 100%;
  height: 750px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
}

.textos-hero h1 {
  font-size: 40px;
  text-align: center;
  color: white;
  margin-bottom: 50px;
 
}

#root > header > div.textos-hero{
  position: relative;
  top: 100px;
}

#root > header > div.textos-hero > h1{
  margin-top: 50px;
}

.textos-hero p {
  font-size: 25px;
  margin-bottom: 20px;
}

.textos-hero {
  height: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textos-hero a {
  display: inline-block;
  text-decoration: none;
  padding: 0px 15px;
  background: rgb(27, 99, 145);
  border-radius: 8px;
  color: white;
}

.svg-hero {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.textos-hero > img {
  width: 250px;
  padding-top: 0px;
}

.textos-hero span {
  font-weight: bold;
}

.textos-hero > a {
  background: transparent;
}

#root > header > div.textos-hero > img{
  margin-top: 150px;
}

#root > header > div.textos-hero > a{
  margin-bottom: 100px;
}

/*ESTILOS GENERALES*/

.contenedor,
.wave-contenedor {
  width: 75%;
  overflow: hidden;
  margin: auto;
  padding: 0 0 80px;
}

.contenedor {
  padding-top: 0;
}

.titulo {
  font-weight: 300;
  font-size: 25px;
  text-align: center;
  margin-bottom: 30px;
}

.titulo.left {
  text-align: justify;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom:10px;
}

.titulo.left2 {
  text-align: justify;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom:10px;
}

.titulo.right {
  text-align: right;
}

/*SECTION*/

.website {
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
}

.website img {
  width: 40%;
}

.website .contenedor-textos-main {
  width: 50%;
}



.parrafo {
  text-align: justify;
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #77104c;
}

.parrafo2 {
  text-align: justify;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.cta {
  display: inline-block;
  text-decoration: none;
  padding: 12px 15px;
  border-radius: 8px;
  color: white;
  background:#77104c ;
}

#icg-info > div.section1 > div > input:hover{
  background: #77104c;
}


.btn-imp{
  display: flex;
}

.titulo {
  font-weight: bold;
  padding-bottom: 50px;
  padding-top: 10px;
}

/*INFO*/

.info {
  background: #679ae7;
  color: white;
  display: flex;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM ICGINFO MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.icg_info{
  height: 400px;
}

#root > div:nth-child(4) > div.flex_a.icg_info > div.section1 > h3{
 margin-left: 0;
}

.image1 > img{
  width: 350px;
}

.section1{
  width: 600px;
  text-align: justify;
  font-size: 1.2em;
}

#root > div:nth-child(4) > div.flex_a.icg_info > div.section1 > article{
  padding-top: 30px;
  color: #77104c;
}

#root > div:nth-child(4){
  background: #eeeaea;
}

.icg_info > .section1 > h3{
  color: #77104c;
}

#root > div:nth-child(4) > div.flex_a.icg_info > div.section1 > div > input{
  margin-top: 35px;
}

.imagen_1 > img{
  width: 400px;
}

.image1 > img{
  border-radius: 15px;
}


#root > div:nth-child(4) > div.flex_a.icg_info > div.image1.imagen_1{
  margin-left: -100px;
  margin-top: 50px;
}

.conocenos{
  display: flex;
  flex-direction: column;
  margin-right: 80px;
  margin-left: 80px;
}

.conocenos > h3{
  text-align: center;
  padding-bottom: 30px;
}

.conocenos > p{
  text-align: justify;
  font-size: 1.2em;
}

#centrado{
  display: block;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 40px;
}

.icono {
  font-size: 40px;
  color: rgb(16, 69, 102);
  margin: 20px auto;
}

.card1 {
  margin-top: 15px;
  margin-bottom: 15px;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM SERVICIOS MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/
#servicios > h2{
  margin-bottom: 50px;
  color: #77104c;
}

.content-servicios > h2, h3{
  color: #1c0f92;
  margin-left: 7%;
}

.section1 > ul > li{
  list-style: none;
  font-weight: bold;
  color: #7e74d8;
  margin-left: 20%;
  margin-top: 5%;
}

.section1 > ul > li:hover{
  cursor: pointer;
  color: #1c0f92;
}

.image1{
  margin-left: 20%;
}

.section1 > h3{
  margin-left: 20%;
  padding-top: 20px;
  padding-bottom: 20px;
}

#root > div.content-servicios > div > div:nth-child(1) > div > img{
  height: 235px;
}

#root > div.content-servicios > div > div:nth-child(3) > div > img{
  height: 235px;
}

.card1 > ul > li{
  text-align: left;
}

body > div.fade.modal.show > div > div > div.modal-body > div > article > p > strong > ul > li{
  text-align: left;
}

body > div.fade.modal.show > div > div > div.modal-body > div > article > p > strong{
  text-align: justify;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM SOMOS MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.fond{
  position:relative;
  padding-top:85px;
  top:0;
  left:0; 
  right:0;
  bottom:0;
  background-color:#eeeaea;
}

.somos_body{
  background: #eeeaea;
}

.somos_title{
  display: flex;
  margin-left: 11%;
  color: #77104c;
}

.somos_title > h3{
  width: 30%;
  margin-left: -35px;
  color: #77104c;
}

.parrafo_somos{
  width: 800px;
  text-align: justify;
  font-size: 1.2em;
}

.flex_a{
  display: flex;
  justify-content: space-around;
}

#root > div:nth-child(7){
  background: #eeeaea;
}

#root > div:nth-child(8) > div.flex_a{
  background: #eeeaea;
}


.pure_css{
  padding:5px; 
  color:#77104c; 
  font-weight:bold; 
  font-size:15px; 
  font-family:'Roboto';
  padding-top:20px;
  text-align: center;
}

.font_style{
  font-weight:400;    
}

.pure_css > a{
  text-decoration:none;
}

.quienes{
  color:#77104c; 
  font-weight:300; 
  font-size:20px; 
  font-family:'Roboto';
  text-align: center;
}

.quienes:hover{
  cursor: pointer;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM VITRINA DE LA NOTICIA MMMMMMMMMMMMMMMMMMMMM*/

.vitrina {
  background: #f9f9f9;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

/*____________________________________________________*/
.caja-not {
  display: flex;
  flex-direction: column;
}
.img-not {
  max-width: 300px;
  max-height: 500px;
  padding: 30px 30px 30px 10px;
  margin-left:0;
}

body > div.fade.modal.show > div > div{
  width: 100%;
}

#example-custom-modal-styling-title{
  margin-left: 0px;
  font-size: 1.5em;
}

.title-not {
  padding-left: 0px;
  padding-top: 30px;
}

.title-not > h2 {
  text-align: center;
}

.body-not {
  padding-left: 30px;
}

.fmt-fecha {
  padding-left: 30px;
}

/*____________________________________________________*/

#title-modal-news > img{
  margin-right: 320px;
}

#title-modal-news{
  color: #1c0f92;
}

.noticia-ddia {
  margin-top: 20px;
}
.img-not {
  max-width: 500px;
  max-height: 500px;
  padding: 35px 30px 40px 50px;
  margin-left: 280px;
  margin-bottom: 0;
}

.title-not {
  padding-left: 50px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.titulo.left {
  padding-bottom: 0;
  font-size: 30px;
}

.body-not {
  padding-left: 50px;
  padding-right: 50px;
}

.fmt-fecha {
  padding-left: 50px;
  padding-top: 0px;
  padding-bottom: 30px;
}

.fmt-fecha > p {
  line-height: 50%;
}

#example-custom-modal-styling-title > img{
  margin-right: 100px;
}

.title-des > img{
  margin-right: 50px;
}

.title-des{
  color: #1c0f92;
}

#title-plus > img{
  margin-right: 50px;
}

#title-plus{
  color: #1c0f92;
}

#example-custom-modal-styling-title{
  color: #1c0f92;
}

.tit-noticia{
  color: #1c0f92;
}


/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM INFO 2 MMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.last-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 40px;
 
}

 .info-ult{
  background: #eeeaea;
}


.last-section img {
  width: 48%;
}

.last-section .contenedor-textos-main {
  width: 90%;
}

.border {
  width: auto;
  border-bottom: 2px solid #683ec9;
}

.boton-cont {
  display: flex;
  justify-content: center;
}

.btn-cont {
  margin-left: auto;
  margin-right: auto;
}

#contacto-id, #inscribete-id{
  display: flex;
}

.texto-contacto, .texto-notificacion{
  margin-left: 150px;
}

.boton-contacto{
  margin-top: 80px;
}

.boton-notificacion{
  margin-top: 80px;
}
/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM FOOTER -CONTACTO MMMMMMMMMMMMMMMMMMMMMMMM*/

footer {
  background: linear-gradient(
    0deg,
    rgba(217, 217, 217, 1) 0%,
    rgba(230, 230, 230, 1) 35%,
    rgba(246, 246, 246, 1) 100%
  );
}

#contacto h1 {
  color: black;
  font-weight: 500;
  text-align: center;
  margin-left: 50px;
}

.resp-cont {
  row-gap: 45px;
}

.reductor {
  width: 60%;
  margin: 0 auto;
}


.btnContacto {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#contacto-id > div.boton-contacto > input:hover{
  background: #77104c;
}

.container-gen {
  display: flex;
  flex-direction: column;
}

.respon {
  display: flex;
  margin: 10px 10px 0 10px;
}

.container-3 {
  display: flex;
  flex-direction: column;
}

.input .nombre {
  width: 100%;
  justify-content: center;
}

.email {
  width: 100%;
}

.nombre {
  margin-right: 20px;
}

.pais {
  width: 20%;
  height: 40px;
  margin-right: 10px;
  margin-left: 15px;
}

.ven {
  text-decoration: underline;
}

.tel1 {
  width: 20%;
  margin-right: 10px;
}

.input {
  background: transparent;
  border: 0;
  color: rgb(102, 96, 96);
  outline: none;
  border: 1px solid rgb(102, 96, 96);
  padding: 20px 10px;
  border-radius: 8px;
}

.input::placeholder {
  color: rgb(102, 96, 96);
  font-family: "raleway", "sans-serif";
}

input[type="text"],
input[type="email"] {
  display: inline-block;
  width: 49%;
  margin-bottom: 30px;
}

.etiqueta{
  font-weight: bold;
  font-size: .8em;
}


.tel {
  width: 35%;
}

.container-3p > select {
  height: 66px;
  width: 25%;
}

.form textarea {
  width: 50%;
  height: 100px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

input[type="submit"] {
  width: 200px;
  text-align: center;
  padding: 14px 0;
  margin-left: auto;
  margin-right: auto;
}

input[type="submit"]:hover {
  cursor: pointer;
  color: #679ae7;
  background: white;
}

select > option {
  color: #1c0f92;
}

.tit-contacto{
  color: #77104c;
}

/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMSOCIALMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

.social {
  background-color: #77104c;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform: scale(1);
  transition: transform 0.6s;
}

.social a {
  text-decoration: none;
  color: rgb(192, 183, 183);
  font-size: 25px;
}

.social a:hover {
  transform: scale(1.4);
  transition: 0.6s ease-in-out;
  color: rgb(175, 186, 197);
}

.social-flex {
  display: flex;
  justify-content: space-around;
  padding-bottom: 30px;
  color: white;
}

.sociales{
  display: flex;
  justify-content: space-around;
}

.twitter {
  padding-right: 40px;
  color: white;
}

.bcv{
  padding-right: 40px;
}

.copyright {
  text-align: center;
  padding-top: 10px;
  color: #d9d7df;
  background: #77104c;
}

.ref-moodle {
  font-size: small;
}

.moodle {
  color: #696464;
}

.block-foto {
  height: 413px;
  object-fit: cover;
}

.style-login {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

/*mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm BARRA DE NAVEGACION mmmmmmmmmmmmmmmmm*/
.container-nav {
  padding: 18px;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0);
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.container-nav ul {
  margin-right: 10%;
}
.container-nav li {
  list-style: none;
  display: inline-flex;
}

.container-nav li a {
  text-decoration: none;
  padding: 14px 20px;
  font-size: 16px;
  color: #f2f2f2;
  display: inline-block;
  text-align: center;
  letter-spacing: 1px;
  transition: all 0.3s ease-in;
  font-family: "Play", sans-serif;
}
.container-nav li a:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #9e3773;
}

.barNavResp{
  display: none;
 
}

#upScrollBtn {
  position: fixed;
  z-index: 1000;
  right: 25px;
  bottom: 25px;
  width: 52px;
  height: 52px;
  color: #77104c;
  text-align: center;
  border-radius: 50%;
  transition: all 0.2s;
}
#upScrollBtn:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.hidden {
  opacity: 0;
  visibility: hidden;
}
.showIn {
  opacity: 1;
  visibility: visible;
}
#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 0;
  left: 0;
}
#progressBar {
  background: linear-gradient(
    to left,
    rgba(80, 0, 172, 0.616),
    rgb(255, 157, 234)
  );
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 0;
}
#progressBarContainer,
#progressBar {
  height: 5px;
}
/* Editor Noticia*/
.sun-editor .se-wrapper .se-wrapper-inner {
  min-height: 150px;
}
/* Sticky toast */
.div-toast {
  display: flex;
  width: 100vw;
  justify-content: flex-end;
  position: sticky !important;
}
.sticky-div {
  position: sticky !important;
  top: 0;
  right: 0;
}
/******** Sliders Noticias  **********/
[class^="number-slide"],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 200px;
}

.number-slide {
  background: rgba(255, 255, 255, 0.8);
  background: linear-gradient(
    128deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #000;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

.item-slide {
  position: relative;
  height: 300px;
}
.slide-img {
  height: 350px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.item-slide .btn-slide {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.navigation-wrapper{
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.institucional, .sociales{
  margin-top: 60px;
}

#contacto-id h1{
  margin-left: 150px;
}


/*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*RESPONSIVE*MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM*/

@media screen and (max-width: 760px) {
  .textos-hero {
    text-align: center;
    font-size: 30px;
  }

  .hero{
    height: 850px;
  }

  /*MMMMMMMMMMMMMMMMMMMM ICG INFO MMMMMMMMMMMMMMMMMMMMMMMMMMM*/

  .icg_info{
    display: block;
    height: 900px;
  }

  .section1{
    max-width: 300px;
    margin-left: 30px;
  }

  .imagen_1 > img{
    width: 300px;
    margin-left: 130px;
  }

  /*MMMMMMMMMMMMMMMMMMM  SERVICIOS  MMMMMMMMMMMMMMMMMMMMMMM*/
  
  .flex_a{
    display: block;
  }

  .image1{
    width: 100px;
    margin-left: 8%;
  }

  /*MMMMMMMMMMMMMMMMMMMMMMMM SOMOS MMMMMMMMMMMMMMMMMMMMMMMMM*/

  .somos_title{
    display: block;
  }

  .parrafo_somos{
    width: 300px;
    text-align: justify;
    font-size: 1.2em;
  }

  .somos_title > h3{
    width: 100%;
    margin-left: 5%;
    margin-bottom: 10%;
  }

  /*MMMMMMMMMMMMMMMMMMMMMMM NOTICIA MMMMMMMMMMMMMMMMMMMMM*/

  .navigation-wrapper{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .vitrina {
    background: #dcdee0;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 30px;
  }

  .caja-not {
    display: flex;
    flex-direction: column;
  }
  .img-not {
    max-width: 300px;
    max-height: 500px;
    padding: 30px 30px 30px 10px;
    margin-left:0;
  }

  body > div.fade.modal.show > div > div{
    width: 100%;
  }

  #example-custom-modal-styling-title{
    margin-left: 0px;
    font-size: 1.5em;
  }

  .title-not {
    padding-left: 0px;
    padding-top: 30px;
  }

  .title-not > h2 {
    text-align: center;
  }

  .body-not {
    padding-left: 30px;
  }

  .fmt-fecha {
    padding-left: 30px;
  }
  

  /*MMMMMMMMMMMMMMMMMMMMMMMM CONTACTANOS MMMMMMMMMMMMMMMMMMM*/

  .info-ult{
    height: 400px;
  }

  .contenedor-textos-main{
    display: block;
    width: 100%;
  }

  #contacto-id{
    display: block;
  }

  .texto-contacto{
    margin-left: 2%;
  }

  .info-ult{
    height: 750px;
  }

  .boton-contacto{
    margin-top: 0;
    margin-left: 20%;
  }

  /*MMMMMMMMMMMMMMMMMMMMMMMMM  SOCIAL MMMMMMMMMMMMMMMMMMMM*/

  .social{
    display: block;
    height: 510px;
    padding-top: 0;
    padding-bottom: 600px;
  }


  .social-flex{
    display: grid;
    margin-left: 15%;
  }

  .direccion{
    padding-top: 10%;
  }


  /*MMMMMMMMMMMMM BARRA DE NAVEGACION SUPERIOR MMMMMMMMMMMM*/
  .container-nav > ul{
    display: none;
  }

  .barNavResp{
    display: block;
    top: 0;
    width: 100%;
    position: relative;
    
  }


}



